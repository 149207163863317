export const environment = {
    production: true,
    apiUrl: 'https://api.race.suirtech.com:8443',
    userUrl: 'https://api.race.suirtech.com:8443',
    version: '2.0.0 | 2020-12-18:35 SUIRTECH.COM',
    firebase: {
        apiKey: 'AIzaSyCxuyzm9ECasr6i_mJxMeOwQOqyjRibRg0',
        authDomain: 'dms-21.firebaseapp.com',
        databaseURL: 'https://dms-21-default-rtdb.europe-west1.firebasedatabase.app',
        projectId: 'dms-21',
        storageBucket: 'dms-21.appspot.com',
        messagingSenderId: '467318545257',
        appId: '1:467318545257:web:49b9bc3258ac233edcbbf8',
        measurementId: 'G-GCJPXWWZ6L'
    }
};
