import {Injectable} from '@angular/core';

import {AngularFirestore} from '@angular/fire/firestore';

@Injectable({providedIn: 'root'})
export class UserService {
    constructor(private firestore: AngularFirestore) {
    }

    getAllUsers(): any {
        return this.firestore.collection('/users').valueChanges();
    }

}
