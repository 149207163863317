import {Component, OnInit} from '@angular/core';
import {SriApiService} from '../../services/sri-api.service';
import {AuthenticationService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UpdateIncidentComponent} from '../../components/update-incident/update-incident.component';

@Component({
    selector: 'app-view-incidents',
    templateUrl: './view-incidents.component.html',
    styleUrls: ['./view-incidents.component.scss']
})
export class ViewIncidentsComponent implements OnInit {

    listAjustments;
    searchText;
    inIFrame = false;

    constructor(private sriService: SriApiService, public authService: AuthenticationService,
                private router: Router, private modalService: NgbModal) {
    }

    ngOnInit() {
        this.sriService.getIncidents()
            .subscribe(data => {
                this.listAjustments = data;
            });

        if (window.location !== window.parent.location) {
            this.inIFrame = true;
        }
    }

    refresh() {
        console.log('refresh called');
        this.sriService.getIncidents()
            .subscribe(data => {
                console.log(data);
                this.listAjustments = data;
            });
    }

    openModal(r) {
        const modalRef = this.modalService.open(UpdateIncidentComponent);
        modalRef.componentInstance.listAjustments = this.listAjustments;

        modalRef.componentInstance.r = r;
        modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
            console.log(receivedEntry);
        });
    }

    redirectToCreate() {
        this.router.navigate(['/incidents']);
    }

    isDMSAdmin() {
        return this.authService.isAdmin();

    }

}
