import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SriApiService} from '../../services/sri-api.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-update-incident',
    templateUrl: './update-incident.component.html',
    styleUrls: ['./update-incident.component.scss']
})
export class UpdateIncidentComponent implements OnInit {
    @Input() public r;
    @Input() public listAjustments;
    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    statuses = ['OPEN', 'CLOSED', 'ACTIONED'];
    types = ['CAUSING COLLISION', 'PIT LANE INFRINGEMENT', 'BAD DRIVING CONDUCT'];
    actionsList = [
        'Warning',
        'Drive Through',
        'Stop and Hold',
        'Time Penalty',
        'Lap Deduction',
        'Points Deduction',
        'Disqualification',
        'Grid Penalty',
        'Pit Lane Start',
        'Race Ban'
        ];


    driverInvolved1;
    driverNames;
    constructor(public activeModal: NgbActiveModal, private sriService: SriApiService) {
    }

    ngOnInit() {
    }

    passBack() {
        this.activeModal.close(this.r);
        this.refresh();
    }


    updateIncident() {
        this.sriService.updateIncident(this.r)
            .subscribe(data => {
                console.log(data);
                this.passBack();
            });
    }


    setRacingIncident() {
        this.r.decision = 'RACING INCIDENT';
        this.r.action = 'NONE';
        this.r.status = 'CLOSED';

        this.sriService.updateIncident(this.r)
            .subscribe(data => {
                console.log(data);
                this.passBack();
            });

    }


    delete() {
        const r =  confirm('sure u want to delete? ' + this.r.id);
        if (r === true) {
            this.sriService.deleteIncident(this.r.id)
                .subscribe(data => {
                    console.log(data);
                    this.passBack();
                });
        } else {

        }
    }

    refresh() {
        console.log('refresh called from udpate comp');
        this.sriService.getIncidents()
            .subscribe(data => {
                console.log(data);
                this.listAjustments = data;
            });
    }

    updateDriverInvolved1Number() {
        this.sriService.getDriverNumber(this.driverInvolved1)
            .subscribe(d => {
                this.r.involvedDrivers = '' + d;
                // console.log('--> ' + this.incident.driverNumber);
            });
    }

}
