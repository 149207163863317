import { Component, OnInit } from '@angular/core';
import {SriApiService} from '../../services/sri-api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AdjustmentComponent} from '../admins/adjustment/adjustment.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-view-adjustments',
  templateUrl: './view-adjustments.component.html',
  styleUrls: ['./view-adjustments.component.scss']
})
export class ViewAdjustmentsComponent implements OnInit {

    listAjustments;
    searchText;

    constructor(private sriService: SriApiService) {
    }

    ngOnInit() {
        this.sriService.getAllAdjustments()
            .subscribe(data => {
                console.log(data);
                this.listAjustments = data;
            });
    }

    // openModal(r) {
    //     const modalRef = this.modalService.open(AdjustmentComponent);
    //     modalRef.componentInstance.r = r;
    //     modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
    //         console.log(receivedEntry);
    //     });
    // }

    refresh() {
        this.sriService.getAllAdjustments()
            .subscribe(data => {
                console.log(data);
                this.listAjustments = data;
            });
    }



}
