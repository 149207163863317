import {Component, OnInit} from '@angular/core';
import {Incident} from './Incident';
import {SriApiService} from '../../../services/sri-api.service';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../services/auth.service';

@Component({
    selector: 'app-incidents',
    templateUrl: './incidents.component.html',
    styleUrls: ['./incidents.component.scss']
})
export class IncidentsComponent implements OnInit {

    incident: Incident;
    driverNames;
    namer;
    setNamer = 'DMS-73222';

    driverInvolved1;

    constructor(private sriService: SriApiService, private router: Router, public authService: AuthenticationService) {
        this.incident = new Incident();
    }

    ngOnInit() {
        this.sriService.getDriverNames()
            .subscribe(data => {
                this.driverNames = data;
            });
    }

    createIncident() {
        console.log('creating incident :: ' + JSON.stringify({data: this.incident.round}, null, 4));

        // if (this.authService.isAdmin()) {
        //     this.namer = this.setNamer;
        // }


        if (this.incident.driverName === undefined) {
            alert('please select Your name');
        } else if (this.incident.round === undefined) {
            alert('please add round');
        } else if (this.incident.involvedDrivers === undefined) {
            alert('please add drivers involved # or name');
        } else if (this.incident.lapNumber === undefined) {
            alert('please add  lapNumber');
        } else if (this.incident.corner === undefined) {
            alert('please add  corner');
        } else if (this.incident.comment.length > 60) {
            alert('please keep comment short and less then 35 chars');
        } else if (this.namer !== this.setNamer) {
            alert('password wrong, check discord for password');
        } else {

            this.incident.championshipId = 0;
            this.incident.decision = 'none';
            this.incident.action = 'none';
            this.incident.status = 'open';
            this.incident.type = 'CAUSING A COLLISION';

            this.sriService.postIncident(this.incident)
                .subscribe(data => {
                    // console.log(data);
                    this.router.navigate(['/viewIncidents']);
                });
        }

    }

    updateNumber() {
        // console.log('on change +++ ' + this.incident.driverName);
        this.sriService.getDriverNumber(this.incident.driverName)
            .subscribe(data => {
                this.incident.driverNumber = +data;
                // console.log('--> ' + this.incident.driverNumber);
            });
    }

    updateDriverInvolved1Number() {
        this.sriService.getDriverNumber(this.driverInvolved1)
            .subscribe(d => {
                this.incident.involvedDrivers = '' + d;
                // console.log('--> ' + this.incident.driverNumber);
            });
    }


    passBack() {
        this.router.navigate(['/viewIncidents']);
    }

}
