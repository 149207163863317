import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SriApiService} from '../../../services/sri-api.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Adjustment} from './Adjustment';

@Component({
    selector: 'app-adjustment',
    templateUrl: './adjustment.component.html',
    styleUrls: ['./adjustment.component.scss']
})
export class AdjustmentComponent implements OnInit {

    @Input() public r;
    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    pointsAdjust = 0;
    reason = 'n/a';
    seconds;

    constructor(private sriService: SriApiService,
                public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
    }

    passBack() {
        this.activeModal.close(this.r);

    }

    makeAdjustment() {
        // alert('wlll make adjsustment');

        const adj = new Adjustment();
        adj.raceEntryId = 0;
        adj.pointsAdjustment = this.pointsAdjust;
        adj.round = 'rd' + this.r.round;
        adj.driverName = this.r.name;
        adj.sessionId = this.r.sessionId;
        adj.seconds = this.seconds;
        adj.reason = this.reason;
        adj.championshipId = 0;

        console.log('makeAdjustment>>> ' + adj);

        this.sriService.postAdjustment(adj)
            .subscribe(data => {
                console.log(data);
                this.passBack();
            });
    }

}
