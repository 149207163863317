import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../services/auth.service';
import {AngularFirestore} from "@angular/fire/firestore";

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
    leaderboards = '/home';
    about = '/about';
    adminLink = '/admin';
    viewAdjustments = '/viewAdjustments';
    incidents = '/incidents';
    viewIncidents = '/viewIncidents';
    registerDriver = '/register-driver';
    signIn = '/signIn';
    homeLink = '';
    races = '/support';
    drivers = '/drivers';
    adminDashboard = '/admin-dashboard';
    // drivers = '/drivers';


    inIFrame = false;

    adminUser = false;

    constructor(public authService: AuthenticationService,
                public afs: AngularFirestore,
                public router: Router) {
    }

    ngOnInit() {
        if (window.location !== window.parent.location) {
            this.inIFrame = true;
        }
    }

    logout() {

        this.authService.signOut();
    }


    loggedIn() {
        return this.authService.isLoggedIn();
    }

    isDMSAdmin() {
            return this.authService.isAdminUser;

    }


}
