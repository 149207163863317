import {Injectable, NgZone} from '@angular/core';
import {auth} from 'firebase/app';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import * as util from 'util'; // has no default export


interface User {
    uid: string;
    email: string;
    photoURL?: string;
    displayName?: string;
    emailVerified: string;
    fcmTokens?: { [token: string]: true };
}

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    userData: any; // Save logged in user data

    public isAdminUser = false;

    constructor(public afs: AngularFirestore,   // Inject Firestore service
                public afAuth: AngularFireAuth, // Inject Firebase auth service
                public router: Router,
                public ngZone: NgZone // NgZone service to remove outside scope warning
    ) {
        /* Saving user data in localstorage when
        logged in and setting up null when logged out */
        this.afAuth.authState.subscribe(user => {
            if (user) {
                this.userData = user;
                localStorage.setItem('user', JSON.stringify(this.userData));
                JSON.parse(localStorage.getItem('user'));

                this.checkAdmin();

                // console.log('user ' + JSON.stringify(this.userData));

            } else {
                localStorage.setItem('user', null);
                JSON.parse(localStorage.getItem('user'));
            }
        });
    }

    // Returns true when user is logged in and email is verified
    isLoggedIn(): boolean {
        // const user = JSON.parse(localStorage.getItem('user'));
        // return (user !== null && user.emailVerified !== false);
        const currentUser = this.afAuth.auth.currentUser;

        return !!currentUser;
    }

    getCurrentUser() {
        return this.userData;
    }

    isAdmin() {
        return this.isAdminUser;
    }

    // Sign in with email/password
    SignIn(email, password) {
        return this.afAuth.auth.signInWithEmailAndPassword(email, password)
            .then((result) => {
                this.ngZone.run(() => {
                    this.router.navigate(['admin-dashboard']);
                });
                this.SetUserData(result.user);
            }).catch((error) => {
                window.alert(error.message);
            });
    }

    // Sign up with email/password
    SignUp(email, password) {
        return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
            .then((result) => {
                /* Call the SendVerificaitonMail() function when new user sign
                up and returns promise */
                this.SendVerificationMail();
                this.SetUserData(result.user);
            }).catch((error) => {
                window.alert(error.message);
            });
    }

    // Send email verfificaiton when new user sign up
    SendVerificationMail() {
        return this.afAuth.auth.currentUser.sendEmailVerification()
            .then(() => {
                this.router.navigate(['verify-email-address']);
            });
    }

    // Reset Forggot password
    ForgotPassword(passwordResetEmail) {
        return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
            .then(() => {
                window.alert('Password reset email sent, check your inbox.');
            }).catch((error) => {
                window.alert(error);
            });
    }

    // Sign in with Google
    GoogleAuth() {
        return this.AuthLogin(new auth.GoogleAuthProvider());
    }

    FacebookAuth() {
        return this.AuthLogin(new auth.FacebookAuthProvider());
    }


    // Auth logic to run auth providers
    AuthLogin(provider) {
        return this.afAuth.auth.signInWithPopup(provider)
            .then((result) => {
                this.ngZone.run(() => {
                    this.router.navigate(['dashboard']);
                });
                this.SetUserData(result.user);
            }).catch((error) => {
                window.alert(error);
            });
    }

    /* Setting up user data when sign in with username/password,
    sign up with username/password and sign in with social auth
    provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
    SetUserData(user) {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
        const userData: User = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified
        };
        return userRef.set(userData, {
            merge: true
        });
    }

    async signOut() {
        await this.afAuth.auth.signOut();
        this.router.navigate(['/']);
    }

    async checkAdmin() {
        return this.afs
            .collection('admins', ref => ref.where('uid', '==', this.userData.uid))
            .valueChanges()
            .forEach(a => {
                console.log(' set ->' + util.inspect(a));
                if (a.length > 0) {
                    console.log('is admin');
                    console.log(util.inspect(a));
                    this.isAdminUser =  true;
                } else {
                    this.isAdminUser = false;
                    console.log('not admin');
                }
            });
    }


}
