export class Adjustment{
    driverName: string;
    sessionId: string;
    round: string;
    championshipId: number;
    pointsAdjustment: number;
    reason: string;
    seconds: number;
    raceEntryId: number;
}
