export class Incident {
    driverName: string;
    driverNumber: number;
    round: string;
    raceNumber: number;
    championshipId: number;
    involvedDrivers: string;
    lapNumber: number;
    corner: number;
    links: string;
    decision: string;
    action: string;
    status: string;
    comment: string;
    type: string;
}
