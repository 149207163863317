import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Driver} from '../components/admins/register-driver/driver';

@Injectable({
  providedIn: 'root'
})
export class DriverService {

  constructor(private firestore: AngularFirestore) {
  }

  getDrivers(): any {
    return this.firestore.collection('/drivers').valueChanges();
  }

  getDriver(id) {
    return this.firestore
      .collection('drivers', ref => ref.where('id', '==', id))
      .valueChanges();
  }

  createDriver(driver: Driver) {
    return this.firestore.collection<Driver>('drivers').add({...driver} as Driver);
  }
}
