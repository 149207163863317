import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RaceService} from '../../services/race.service';
import * as util from 'util';


@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    @Input() public r;
    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    rasRaces;

    constructor(private raceService: RaceService,
                private modalService: NgbModal,
                public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        console.log('went for laps.. ' + this.r.driverName);
        this.raceService.getRasRacesFastestLapDriver(this.r.driverName)
            .subscribe((data) => {
                this.rasRaces = data;
            });
    }

    fixName(username) {
        return username.replace(/[0-9]/g, '');
    }

    passBack() {
        this.activeModal.close(this.r);
    }

    getFlag(country) {
        let code = 'ie';

        switch (country) {
            case 'Ireland':
                code = 'ie';
                break;
            case 'UK':
                code = 'gb';
                break;
            case 'Lithuania':
                code = 'lt';
                break;
            case 'USA':
                code = 'us';
                break;
            case 'Portugal':
                code = 'pt';
                break;
        }

        return `https://www.countryflags.io/${code}/flat/32.png`;
    }

    getDriverImage(driverName) {
        // console.log('got url ::: ' + s);
        return 'https://firebasestorage.googleapis.com/v0/b/dms-21.appspot.com/o/driver_profiles%2F'
            + encodeURI(driverName) + '?alt=media&token=a9b536d1-e5fa-4f66-b32f-3703bb916765';
    }
}
