import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AgmCoreModule} from '@agm/core';
import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HomeComponent} from './components/home/home.component';
import {NavComponent} from './components/nav/nav.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AboutComponent} from './components/about/about.component';
import {RacesComponent} from './components/races/races.component';
import {RegisterComponent} from './components/register/register.component';
import {RulesComponent} from './components/rules/rules.component';
import {FooterComponent} from './components/footer/footer.component';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ProfileComponent} from './components/profile/profile.component';
import {AdminComponent} from './components/admins/admin/admin.component';
import {FilterPipe} from './components/admins/admin/FilterPipe';
import {AdjustmentComponent} from './components/admins/adjustment/adjustment.component';
import {SigninComponent} from './components/signin/signin.component';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AuthenticationService} from './services/auth.service';
import {AuthGuard} from './guards/auth.guard';
import {BasicAuthInterceptor} from './guards/basic-auth.interceptor';
import {DriversComponent} from './components/drivers/drivers.component';
import {ViewAdjustmentsComponent} from './components/view-adjustments/view-adjustments.component';
import {IncidentsComponent} from './components/admins/incidents/incidents.component';
import {ViewIncidentsComponent} from './components/view-incidents/view-incidents.component';
import {AdjFilterPipe} from './components/view-adjustments/AdjFilterPipe';
import {IncidentsFilterPipe} from './components/view-incidents/IncidentsFilterPipe';
import {UpdateIncidentComponent} from './components/update-incident/update-incident.component';
import {AngularFirestore, AngularFirestoreModule} from '@angular/fire/firestore';
import {environment} from '../environments/environment.prod';
import {AngularFireModule} from '@angular/fire';
import {UserComponent} from './user/user.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {RegisterDriverComponent} from './components/admins/register-driver/register-driver.component';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {IraceEntryComponent} from './components/admins/irace-entry/irace-entry.component';
import { ProTableComponent } from './components/pro-table/pro-table.component';
import { UploadPicsComponent } from './components/upload-pics/upload-pics.component';
import { AdminDashboardComponent } from './components/admins/admin-dashboard/admin-dashboard.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        NavComponent,
        AboutComponent,
        RacesComponent,
        RegisterComponent,
        RulesComponent,
        FooterComponent,
        ProfileComponent,
        AdminComponent,
        FilterPipe,
        IncidentsFilterPipe,
        AdjFilterPipe,
        AdjustmentComponent,
        SigninComponent,
        DriversComponent,
        ViewAdjustmentsComponent,
        IncidentsComponent,
        ViewIncidentsComponent,
        UpdateIncidentComponent,
        UserComponent,
        ForgotPasswordComponent,
        RegisterDriverComponent,
        IraceEntryComponent,
        ProTableComponent,
        UploadPicsComponent,
        AdminDashboardComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDy3B3QiYr-WsZPX99FqLXu4EKPGl0X6cs'
        }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFontAwesomeModule,
        AngularFireAuthModule,
        AngularFirestoreModule,
        HttpClientModule,
        NgbModule,
        AngularFireStorageModule
    ],
    exports: [UpdateIncidentComponent],
    providers: [NgbActiveModal, AuthGuard, AuthenticationService, AngularFirestore,
        {provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true},
    ],
    bootstrap: [AppComponent],
    entryComponents: [ProfileComponent, AdjustmentComponent, UpdateIncidentComponent]

})
export class AppModule {
}
