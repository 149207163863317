import {Component, OnInit} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/storage';
import {Observable} from 'rxjs/index';

@Component({
    selector: 'app-upload-pics',
    templateUrl: './upload-pics.component.html',
    styleUrls: ['./upload-pics.component.scss']
})
export class UploadPicsComponent implements OnInit {

    username;
    uploadedOrig;
    ref;
    task;
    uploadProgress;
    result;
    downloadURL: Observable<string>;

    constructor(private afStorage: AngularFireStorage) {
    }

    ngOnInit() {
    }

    upload() {

        if (confirm('You are Uploading Photo for Driver ' + this.username + '\n is this Correct?')) {
            console.log('Implement delete functionality here');
        }

        this.ref = this.afStorage.ref('driver_profiles/' + this.username);
        this.task = this.ref.put(this.uploadedOrig);
        this.uploadProgress = this.task.percentageChanges();
        this.downloadURL = this.ref.getDownloadURL();
    }

    async onFileChanged(event) {
        console.log('file uploaded...');

        this.uploadedOrig = event.target.files[0];

    }


    check(isUploaded) {
        if (isUploaded) {
            this.result = 'Photo was uploaded';
            setTimeout(() => {

                    this.username = null;
                    this.uploadProgress = null;
                    this.uploadedOrig = null;
                    this.downloadURL = null;
                },
                4000);
        }
    }
}


