import {Component, NgZone, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Driver} from './driver';
import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/storage';
import {Observable} from 'rxjs/index';
import {DriverService} from '../../../services/driver.service';

@Component({
    selector: 'app-register-driver',
    templateUrl: './register-driver.component.html',
    styleUrls: ['./register-driver.component.scss']
})
export class RegisterDriverComponent implements OnInit {
    iRacingUsernameText;
    iRacingIdText;
    firstNameText;
    lastNameText;
    countryText;
    downloadableURL;

    basePath = '/driver_profiles';

    progressValue: Observable<number>;

    task: AngularFireUploadTask;


    constructor(public router: Router,
                public ngZone: NgZone,
                private fireStorage: AngularFireStorage,
                private driverService: DriverService) {
    }

    ngOnInit() {
    }


    async onFileChanged(event) {
        const file = event.target.files[0];
        if (file) {
            const filePath = `${this.basePath}/${this.iRacingUsernameText}`;
            this.task =  this.fireStorage.upload(filePath, file);

            this.progressValue = this.task.percentageChanges();

            (await this.task).ref.getDownloadURL().then(url => {this.downloadableURL = url; });

        } else {
            alert('No images selected');
            this.downloadableURL = '';
        }
    }


    registerDriver(iRacingUsername, iRacingId, firstName, lastName, country) {
        const driver = new Driver();

        driver.iRacingUsername = iRacingUsername;
        driver.iRacingId = iRacingId;
        driver.firstName = firstName;
        driver.lastName = lastName;
        driver.country = country;


        this.driverService.createDriver(driver).then(() => {
            this.ngZone.run(() => {
                this.router.navigate(['/']);
            });
        }).then(_ => alert('Saved'))
            .catch((error) => {
                alert(error.message);
            });
    }

}
