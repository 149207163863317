import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {first} from 'rxjs/internal/operators';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
    constructor(public authService: AuthenticationService) { }

    ngOnInit() {
    }
}
