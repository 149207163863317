import {Component, OnInit} from '@angular/core';
import {RaceService} from '../../services/race.service';
import {Router} from '@angular/router';

// const util = require('util');

@Component({
    selector: 'app-pro-table',
    templateUrl: './pro-table.component.html',
    styleUrls: ['./pro-table.component.scss']
})
export class ProTableComponent implements OnInit {

    listSeriesPositions;
    idmcRankings;
    currentChampionShip;
    showLeaderBoard = true;
    showRankings = false;
    tableTitle;
    inIFrame;

    constructor(private raceService: RaceService, public router: Router) {
    }

    ngOnInit() {

        this.raceService.getCurrentChampionship().subscribe((c) => {
            // console.log('got current champions :: ' + util.inspect(c));
            this.currentChampionShip = c[0];
            this.tableTitle = c[0]['name'] + ' LEADERBOARD';
        });

        this.raceService.getChanmpionShipPositions()
            .subscribe((data) => {
                this.listSeriesPositions = data;
            });

        if (window.location !== window.parent.location) {
            this.inIFrame = true;
        }
    }

    filterPro() {
        this.showLeaderBoard = true;
        this.showRankings = false;
    }

    filterRankings() {
        this.showLeaderBoard = false;
        this.showRankings = true;
        this.raceService.getIDMCRankins()
            .subscribe((data) => {
                this.idmcRankings = data;
            });
    }

    replaceNotRunRaceIndicator(ro) {
        if (ro === -1) {
            return '-';
        } else {
            return ro;
        }
    }


    fixName(username) {
        if (username === 'Michael Cullen2') {
            return 'Victor Cullen';
        } else if (username === 'Damien Mcmullen') {
            return 'Damien McMullen';
        } else if (username === 'Jonny Mcmullan') {
            return 'Jonny McMullan';
        }
        return username.replace(/[0-9]/g, '');
    }

}
