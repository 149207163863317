import {Component, OnInit} from '@angular/core';
import {SriApiService} from '../../services/sri-api.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-races',
  templateUrl: './races.component.html',
  styleUrls: ['./races.component.css']
})
export class RacesComponent implements OnInit {

  allRaceList;
  raceFinishesList;
  closeResult: string;

  currentRaceFinishers = 0;

  raceHeading: string;

  constructor(private sriService: SriApiService,
              private modalService: NgbModal) {

  }

  ngOnInit() {
    this.sriService.getAllRaces()
      .subscribe(data => {
        // console.log(data);
        this.allRaceList = data;
      });
  }

  // fixTackName(orgTN) {
  //   return orgTN.split('_')[0].toUpperCase();
  // }

  // fixName(name) {
  //   if (name === '[UNK_DRIVER]') {
  //     return name;
  //   }
  //   const fName = name.split(' ')[0];
  //   const lName = name.split(' ')[1];
  //   return fName.substr(0, 1) + ' ' + lName;
  // }

  // stringData(stringDate) {
  //   // 200501_215553_R
  //
  //   const year = stringDate.substring(0, 1);
  //   const month = stringDate.substring(2, 4);
  //   const day = stringDate.substring(4, 6);
  //
  //   const hr = stringDate.substring(7, 9);
  //   const mm = stringDate.substring(9, 11);
  //
  //   return month + '/' + day + ' ' + hr + ':' + mm;
  // }

  setLapTime(millis) {
    const d = new Date(millis);
    return d.getMinutes() + ':' + this.pad(d.getSeconds()) + ':' + this.milli(d.getMilliseconds());
  }

  open(content, race) {
    // this.raceHeading =
    //   this.fixTackName(race.trackName) + ' | ' +
    //   this.stringData(race.raceTime) +
    //   ' | Winner : ' + this.fixName(race.winner);

    this.sriService.getRaceFinishes(race.sessionId)
      .subscribe(data => {
        // console.log(data);
        this.raceFinishesList = data;
        this.currentRaceFinishers = this.raceFinishesList.length;

        this.modalService.open(content, {
          ariaLabelledBy: 'modal-basic-title',
          size: 'lg',
          windowClass: 'raceClass'
        }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });

      });


  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  pad(num) {
    let s = num + '';
    while (s.length < 2) {
      s = '0' + s;
    }
    return s;
  }

  milli(value) {
    const zeroes = new Array(3 + 1).join('0');
    return (zeroes + value).slice(-3);
  }

  getTrack(heading) {
    console.log(heading);

    const track = heading.split('|')[0].trim().toUpperCase();
    console.log('got track ' + track);
    return track;
  }

}
