import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class RaceService {

    constructor(private firestore: AngularFirestore) {
    }

    getIRaces(): any {
        return this.firestore.collection('/iraces').valueChanges();
    }

    getIRace(sessionId) {
        return this.firestore
            .collection('iraces', ref => ref.where('id', '==', sessionId))
            .valueChanges();
    }

    createIRace(irace: any) {
        return this.firestore.collection('iraces').doc(irace.raceType.substring(0, 1) + irace.sessionId).set({...irace} as any);
    }

    createQuali(irace: any) {
        return this.firestore.collection('iraces').doc(irace.raceType.substring(0, 1) + irace.sessionId).set({...irace} as any);
    }

    cleanIrace(iraceId) {
        return this.firestore.collection('iraces').doc(iraceId).delete();
    }

    cleanRasRace(id) {
        return this.firestore.collection('ras-races').doc(id).delete();
    }

    cleanQualis(id) {
        return this.firestore.collection('ras-qualifyings').doc(id).delete();
    }

    getChanmpionShipPositions(): any {
        return this.firestore.collection('championship-positions-0221',
            ref => ref.orderBy('totalPoints', 'desc'))
            .valueChanges();
    }

    getIDMCRankins(): any {
        return this.firestore.collection('championship-positions',
            ref => ref.where('country', '==', 'IRL').orderBy('totalPoints', 'desc'))
            .valueChanges();
    }

    getCurrentChampionship() {
        return this.firestore
            .collection('championships', ref => ref.where('current', '==', true))
            .valueChanges();
    }

    getRasRacesFastestLapDriver(driverId): any {
        return this.firestore.collection('ras-races',
            ref => ref.where('fastestLapDriver', '==', driverId))
            .valueChanges();
    }


}
