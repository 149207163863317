import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SriApiService {
    environment;

    constructor(private http: HttpClient) {
        this.environment = environment;
    }


    getAllRaces() {
        return this.http.get(this.environment.apiUrl + '/api/v1/champpositions/allRaces', this.getHeaders());
    }

    getAllChampPositions() {
        return this.http.get(this.environment.apiUrl + '/api/v1/champpositions/all', this.getHeaders());
    }

    getProChampPositions() {
        return this.http.get(this.environment.apiUrl + '/api/v1/champpositions/positions?id=1', this.getHeaders());
    }

    getProAmChampPositions() {
        return this.http.get(this.environment.apiUrl + '/api/v1/champpositions/positions?id=2', this.getHeaders());
    }

    getRaceFinishes(sessionId) {
        return this.http.get(this.environment.apiUrl + '/api/v1/champpositions/raceEntriesBySession?sessionId=' + sessionId, this.getHeaders());
    }

    getProRankings() {
        return this.http.get(this.environment.apiUrl + '/api/v1/champpositions/top10Pro', this.getHeaders());
    }

    getProAmRankings() {
        return this.http.get(this.environment.apiUrl + '/api/v1/champpositions/top3ProAm', this.getHeaders());
    }

    getAllRaceEntries() {
        return this.http.get(this.environment.apiUrl + '/api/v1/irace/getAllRaceEntries', this.getHeaders());
    }

    getAllAdjustments() {
        return this.http.get(this.environment.apiUrl + '/api/v1/adjustment/all', this.getHeaders());
    }

    postAdjustment(adj) {
        return this.http.post(this.environment.apiUrl + '/api/v1/adjustment/apply', adj, this.getHeaders());
    }

    postIncident(incident) {
        return this.http.post(this.environment.apiUrl + '/api/v1/incident/save', incident, this.getHeaders());
    }

    updateIncident(incident) {
        return this.http.put(this.environment.apiUrl + '/api/v1/incident/update', incident, this.getHeaders());
    }

    deleteIncident(id) {
        return this.http.delete(this.environment.apiUrl + '/api/v1/incident/delete?id=' + id, this.getHeaders());
    }

    getIncidents() {
        return this.http.get(this.environment.apiUrl + '/api/v1/incident/all', this.getHeaders());
    }

    getDriverNames() {
        return this.http.get(this.environment.apiUrl + '/api/v1/champpositions/getDriverNames', this.getHeaders());
    }

    getDriverNumber(driverName) {
        return this.http.get(this.environment.apiUrl + '/api/v1/champpositions/getDriverNumber?driverName='
            + driverName, this.getHeaders());
    }

    auth() {
        const body = {
            email: 'user@email.com',
            password: 'password'
        };
        return this.http.post(this.environment.apiUrl + '/api/v1/auth', body, this.getHeaders());
    }


    private getHeaders() {
        return {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        };
    }

}
