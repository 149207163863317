import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminComponent} from './components/admins/admin/admin.component';
import {SigninComponent} from './components/signin/signin.component';
import {AuthGuard} from './guards/auth.guard';
import {DriversComponent} from './components/drivers/drivers.component';
import {ViewAdjustmentsComponent} from './components/view-adjustments/view-adjustments.component';
import {ViewIncidentsComponent} from './components/view-incidents/view-incidents.component';
import {IncidentsComponent} from './components/admins/incidents/incidents.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {RegisterDriverComponent} from './components/admins/register-driver/register-driver.component';
import {IraceEntryComponent} from './components/admins/irace-entry/irace-entry.component';
import {ProTableComponent} from './components/pro-table/pro-table.component';
import {HomeComponent} from './components/home/home.component';
import {UploadPicsComponent} from './components/upload-pics/upload-pics.component';
import {AdminDashboardComponent} from './components/admins/admin-dashboard/admin-dashboard.component';
import {NavComponent} from './components/nav/nav.component';


const routes: Routes = [
    {path: '', component: ProTableComponent},
    {path: 'home', component: ProTableComponent},
    {path: 'leaderboard', component: ProTableComponent},
    {path: '2020', component: HomeComponent},
    {path: 'admin', component: AdminComponent, canActivate: [AuthGuard]},
    {path: 'signIn', component: SigninComponent},
    {path: 'drivers', component: DriversComponent},
    {path: 'viewAdjustments', component: ViewAdjustmentsComponent},
    {path: 'incidents', component: IncidentsComponent},
    {path: 'viewIncidents', component: ViewIncidentsComponent},
    {path: 'forgot-password', component: ForgotPasswordComponent},
    {path: 'register-driver', component: RegisterDriverComponent},
    {path: 'irace-entry', component: IraceEntryComponent},
    {path: 'photos', component: UploadPicsComponent},
    {path: 'admin-dashboard', component: AdminDashboardComponent},
    {path: 'nav', component: NavComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
