export class Driver {

    id: string;
    firstName: string;
    lastName: string;
    country: string;
    photo: string;
    iRacingId: string;
    iRacingUsername: string;
}


