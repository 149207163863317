import {Component, OnInit} from '@angular/core';
import {SriApiService} from '../../../services/sri-api.service';
import {AuthenticationService} from '../../../services/auth.service';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AdjustmentComponent} from "../adjustment/adjustment.component";

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

    listRaceEntries;
    searchText;

    constructor(private sriService: SriApiService, private modalService: NgbModal) {
    }

    ngOnInit() {
        this.sriService.getAllRaceEntries()
            .subscribe(data => {
                console.log(data);
                this.listRaceEntries = data;
            });
    }

    openModal(r) {
        const modalRef = this.modalService.open(AdjustmentComponent);
        modalRef.componentInstance.r = r;
        modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
            console.log(receivedEntry);
        });
    }

    refresh() {
        this.sriService.getAllRaceEntries()
            .subscribe(data => {
                console.log(data);
                this.listRaceEntries = data;
            });
    }

}
