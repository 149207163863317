import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'adjFilter'
})
export class AdjFilterPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) { return []; }
        if (!searchText) { return items; }
        searchText = searchText.toLowerCase();
        return items.filter(it => {
            return it.driverName.toLowerCase().includes(searchText);
        });
    }
}
