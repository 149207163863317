import {Component, OnInit} from '@angular/core';
import {SriApiService} from '../../services/sri-api.service';
import {STPosition} from './STPosition';

import {ProfileComponent} from '../profile/profile.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from "../../services/user.service";
import {User} from "../../models/User";


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    listSeriesPositions;
    totalRaces: any;
    seriesName = 'Indy PM 18';
    className = 'PRO';

    showLeaderBoard = true;
    showRankings = false;

    listRankingsPro;
    listRankingsProAm;
    users: User[];
    inIFrame = false;

    constructor(private sriService: SriApiService, private modalService: NgbModal, private userService: UserService) {
    }

    ngOnInit() {
        // console.log('Loading ' + this.seriesName);
        this.sriService.getProChampPositions()
            .subscribe(data => {
                console.log(data);
                this.listSeriesPositions = this.dropLowestRound(data);
                this.sriService.getAllRaces().subscribe(totalRaceData => {
                    this.totalRaces = totalRaceData;

                    if (this.totalRaces === 0) {
                        alert('Opps something has gone wrong!');
                    }

                });


            });

        this.users = this.userService.getAllUsers();

        if (window.location !== window.parent.location) {
            this.inIFrame = true;
        }
    }

    fixName(username) {
        if (username === 'Michael Cullen2') {
            return 'Victor Cullen';
        } else if (username === 'Damien Mcmullen') {
            return 'Damien McMullen';
        } else if (username === 'Jonny Mcmullan') {
            return 'Jonny McMullan';
        }
        return username.replace(/[0-9]/g, '');
    }

    filterPro() {
        this.showLeaderBoard = true;
        this.showRankings = false;
        this.listSeriesPositions = [];
        this.className = 'PRO';
        this.sriService.getProChampPositions()
            .subscribe(data => {
                this.listSeriesPositions = this.dropLowestRound(data);
            });
    }

    filterProAm() {
        this.showLeaderBoard = true;
        this.showRankings = false;
        this.listSeriesPositions = [];
        this.className = 'ProAm';
        this.sriService.getProAmChampPositions()
            .subscribe(data => {
                this.listSeriesPositions = this.dropLowestRound(data);
            });
    }

    filterRankings() {
        this.className = 'IDMC Rankings';
        this.showLeaderBoard = false;
        this.showRankings = true;

        this.sriService.getProRankings()
            .subscribe(data => {
                this.listRankingsPro = this.dropLowestRound(data);
            });

        // this.sriService.getProAmRankings()
        //     .subscribe(data => {
        //         this.listRankingsProAm = data;
        //     });
    }

    dropLowestRound(data) {
        const newData = [];
        for (let i = 0; i < data.length; i++) {
            newData.push(this.getLowestRound(data[i]));
        }

        return newData.sort((a, b) => (a.points < b.points) ? 1 : -1);

        // return data;
    }

    getLowestRound(position: STPosition) {
        const roundTotals: number[] = [];
        roundTotals[0] = position.rd1;
        roundTotals[1] = position.rd2;
        roundTotals[2] = position.rd3;
        roundTotals[3] = position.rd4;
        // roundTotals[4] = position.rd5;
        // roundTotals[5] = position.rd6;
        // roundTotals[6] = position.rd7;
        // roundTotals[7] = position.rd8;
        // roundTotals[8] = position.rd9;
        // console.log('round totals >>> ' + roundTotals);

        const min = Math.min(...roundTotals);
        // console.log(position.username + ' >> round min >>> ' + min);

        position.points = position.points - min;
        position.droppedRound = roundTotals.indexOf(min) + 1;

        return position;

    }

    openModal(r) {
        const modalRef = this.modalService.open(ProfileComponent);
        modalRef.componentInstance.r = r;
        modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
            console.log(receivedEntry);
        });
    }


}
