import {Component, OnInit} from '@angular/core';
import {RaceService} from '../../../services/race.service';

@Component({
    selector: 'app-irace-entry',
    templateUrl: './irace-entry.component.html',
    styleUrls: ['./irace-entry.component.scss']
})
export class IraceEntryComponent implements OnInit {

    levels = ['PRO', 'PRO-AM', 'NOVICE', 'MIXED'];
    raceTypes = ['HEAT', 'FEATURE'];

    sessionId;
    championship;
    round = 0;
    level = 'PRO';
    raceType = 'HEAT';
    track = '';
    dor = '';
    numberOfDrivers;
    uploadedJson;
    uploaded;
    winner = 'WINNER : UPLOAD TO SEE';
    private uploadedOrig: any;

    enableButton = true;

    constructor(private raceService: RaceService) {
    }

    ngOnInit() {

    }

    addIRace() {

        if (this.raceType === 'HEAT') {
            console.log('rt is heat ');
            this.uploadedJson = this.uploadedOrig.session_results[2];
            this.uploadedJson.qualifying = this.uploadedOrig.session_results[3];

            this.winner = this.uploadedOrig.session_results[2].results[0].display_name;

        } else if (this.raceType === 'FEATURE') {
            console.log('rt is feat ');

            this.uploadedJson = this.uploadedOrig.session_results[0];
            this.winner = this.uploadedOrig.session_results[0].results[0].display_name;
        }


        this.uploadedJson.round = this.round;
        this.uploadedJson.level = this.level;
        this.uploadedJson.sessionId = this.sessionId;
        this.uploadedJson.championship = this.championship;
        this.uploadedJson.level = this.level;
        this.uploadedJson.raceType = this.raceType;
        this.uploadedJson.track = this.track;
        this.uploadedJson.dor = this.dor;
        this.uploadedJson.numberOfDrivers = this.numberOfDrivers;

        this.enableButton = false;

        this.raceService.createIRace(this.uploadedJson).then(() => {
            alert('iRace ' + this.sessionId + ' Uploaded OK');

            if (this.round === 1) {
                console.log('ROUND 1 so pausing btton....');
                this.pauseAddingRace();
            }


        }).catch(() => {
            alert('issue uploading');
        });

    }

    async onFileChanged(event) {
        console.log('file uploaded...');
        this.uploadedOrig = event.target.files[0];

        console.log('Uploaded ' + this.uploaded);
        const fileReader = new FileReader();
        fileReader.readAsText(event.target.files[0], 'UTF-8');
        fileReader.onload = () => {
            this.uploadedOrig = JSON.parse(fileReader.result as string);
            this.sessionId = this.uploadedOrig.subsession_id;
            this.championship = this.uploadedOrig.league_season_name;
            this.track = this.uploadedOrig.track.track_name;
            this.dor = this.uploadedOrig.start_time;
            this.numberOfDrivers = this.uploadedOrig.session_results[0].results.length;


            if (this.raceType === 'HEAT') {
                console.log('rt is heat ');

                this.winner = this.uploadedOrig.session_results[2].results[0].display_name;

            } else if (this.raceType === 'FEATURE') {
                console.log('rt is feat ');
                this.winner = this.uploadedOrig.session_results[0].results[0].display_name;
            }

        };
        fileReader.onerror = (error) => {
            console.log(error);
        };

    }


    clean() {
        this.raceService.cleanIrace('F37234682');
        this.raceService.cleanIrace('H37234682');

        this.raceService.cleanQualis('Q37234682');

        this.raceService.cleanRasRace('F37234682');
        this.raceService.cleanRasRace('H37234682');

        // this.raceService.cleanRasRace('35304956');


    }

    updateRaceType() {
        this.raceType = ( this.round & 1 ) ? 'HEAT' : 'FEATURE';
    }


    pauseAddingRace() {
        setTimeout(() => {
            console.log('waiting for Round 1 to sync.....');
            this.enableButton = true;
            this.round = 2;
        }, 120000);
    }

}
