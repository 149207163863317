import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProfileComponent} from '../profile/profile.component';
import {RaceService} from '../../services/race.service';

@Component({
    selector: 'app-drivers',
    templateUrl: './drivers.component.html',
    styleUrls: ['./drivers.component.scss']
})
export class DriversComponent implements OnInit {
    listSeriesPositions;
    className = 'PRO';
    inIFrame = false;

    myImgUrl = 'https://firebasestorage.googleapis.com/v0/b/dms-21.appspot.com/o/driver_profiles%2F'
        + 'unkown?alt=media&token=a9b536d1-e5fa-4f66-b32f-3703bb916765';


    constructor(private raceService: RaceService, private modalService: NgbModal) {
    }

    ngOnInit() {


        this.raceService.getChanmpionShipPositions()
            .subscribe((data) => {
                this.listSeriesPositions = data;
            });
        if (window.location !== window.parent.location) {
            this.inIFrame = true;
        }

    }

    fixName(username) {
        if (username === 'Michael Cullen2') {
            return 'Victor Cullen';
        } else if (username === 'Damien Mcmullen') {
            return 'Damien McMullen';
        } else if (username === 'Jonny Mcmullan') {
            return 'Jonny McMullan';
        }
        return username.replace(/[0-9]/g, '');
    }


    openModal(r) {
        const modalRef = this.modalService.open(ProfileComponent);
        modalRef.componentInstance.r = r;
        modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
            console.log(receivedEntry);
        });
    }

    getFlag(country) {
        let code = 'ie';

        switch (country) {
            case 'Ireland':
                code = 'ie';
                break;
            case 'UK':
                code = 'gb';
                break;
            case 'Lithuania':
                code = 'lt';
                break;
            case 'USA':
                code = 'us';
                break;
            case 'Portugal':
                code = 'pt';
                break;
        }

        return `https://www.countryflags.io/${code}/flat/24.png`;
    }

    getDriverImage(driverName) {
        // console.log('got url ::: ' + s);
        return 'https://firebasestorage.googleapis.com/v0/b/dms-21.appspot.com/o/driver_profiles%2F'
            + encodeURI(driverName) + '?alt=media&token=a9b536d1-e5fa-4f66-b32f-3703bb916765';
    }

    updateUrl(event) {
        return 'https://firebasestorage.googleapis.com/v0/b/dms-21.appspot.com/o/driver_profiles%2F'
        + 'unkown?alt=media&token=a9b536d1-e5fa-4f66-b32f-3703bb916765';
    }
}
